<template>
  <router-view></router-view>
  <div style="height: 5rem;"></div>
  <hello-world></hello-world>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
export default {
  components: { HelloWorld },
  
}
</script>
<template>
  <van-cell-group title="福利申请">
  <van-form @submit="onSubmit">
  <van-field
    v-model="bookname"
    name="作品名"
    label="作品名"
    placeholder="输入作品名称，不要附带书名号"
    :rules="[{ required: true, message: '请填写作品名称' }]"
  />
  <van-field
    v-model="author"
    name="作者名"
    label="作者名"
    placeholder="请输入作者笔名"
    :rules="[{ required: true, message: '请填写笔名' }]"
  />

  <van-field
    v-model="qqnumber"
    name="QQ"
    label="联系QQ"
    placeholder="请填入联系QQ"
    :rules="[{ required: true, message: '请填写QQ' }]"
  />
  <van-field name="switch" label="作者约">
  <template #input>
    <van-switch v-model="SignTypeSwitch" size="24" active-color="#07c160" />
  </template>
</van-field>
  <van-cell title="如果是作者约，请开启此开关" />
  <van-field
    v-model="updateword"
    name="更新字数"
    label="本月更新"
    placeholder="在这里输入本月更新字数，如180000"
    :rules="[{ required: true, message: '请填写本月更新字数' }]"
  />
    <van-field
    v-model="notupdate"
    name="断更天数"
    placeholder="请在这里输入断更天数，如果断更两天请输入:2"
    :rules="[{ required: true, message: '请填写本月断更天数，全勤请写0' }]"
  />
    <van-checkbox-group v-model="result">
<van-cell>
  <template #title>
  <van-checkbox name="qianyue1" checked-color="#07c160">签约奖(入V前)</van-checkbox>
  </template>
</van-cell>
<van-cell>
  <template #title>
  <van-checkbox name="qianyue2" checked-color="#07c160">签约奖(入V后)</van-checkbox>
  </template>
</van-cell>
<van-cell>
  <template #title>
  <van-checkbox name="jidujiang" checked-color="#07c160">季度奖</van-checkbox>
  </template>
</van-cell>
<van-cell>
  <template #title>
  <van-checkbox name="yuejingbutie" checked-color="#07c160">月经补贴</van-checkbox>
  </template>
</van-cell>
<van-cell>
  <template #title>
  <van-checkbox name="dedede" checked-color="#07c160">"的得地"规范使用倡导</van-checkbox>
  </template>
</van-cell>
<van-cell>
  <template #title>
  <van-checkbox name="wanbenxuyue1" checked-color="#07c160">完本续约奖[30万]</van-checkbox>
  </template>
</van-cell>
<van-cell>
  <template #title>
  <van-checkbox name="wanbenxuyue2" checked-color="#07c160">完本续约奖[60万]</van-checkbox>
  </template>
</van-cell>
<van-cell>
  <template #title>
  <van-checkbox name="quanqin1" checked-color="#07c160">日更3000全勤奖</van-checkbox>
  </template>
</van-cell>
<van-cell>
  <template #title>
  <van-checkbox name="quanqin2" checked-color="#07c160">日更6000全勤奖</van-checkbox>
  </template>
</van-cell>
</van-checkbox-group>
  <div style="margin: 16px;">
    <van-button  block type="success" native-type="submit">
      提交
    </van-button>
  </div>
</van-form>
</van-cell-group>
<van-overlay :show="show">
  <div class="wrapper">
<van-loading size="24px" vertical>加载中...</van-loading>
  </div>
</van-overlay>
</template>

<script>
export default {
  data() {
    return {
      bookname: '',
      author: localStorage.author,
      qqnumber: localStorage.qqnumber,
      SignTypeSwitch: false,
      result:[],
      updateword:'',
      notupdate:'',
      show: false, 
    }
  },
  methods: {
    onSubmit(){
      const remarktxt = '更新字数:' + this.updateword + ',断更天数:' + this.notupdate;
      const signtype = this.SignTypeSwitch == true ? 1 : 2;
      this.show = true; //开启遮罩层
      this.axios.post('/fuli',{
        author: this.author,
        bookname: this.bookname,
        qqnumber: this.qqnumber,
        sign_type: signtype,
        month_update:remarktxt,
        fulilist:this.result,
        useragent:navigator.userAgent
      },{timeout:15000}).then((res)=>{
        if(res.data.code === 500){
          this.show = false;
          this.$notify({
          type: 'danger',
          message: res.data.message,
        });
        } else { 
          this.show = false;
          localStorage.setItem('author',this.author);
          localStorage.setItem('qqnumber',this.qqnumber);
          this.$dialog.alert({
            message: '申请成功，请注意不要重复提交'
          });
        }
      }).catch((err)=>{
        console.log(err);
        this.show = false;
        this.$notify({
          type: 'danger',
          message: 'Error:'+ err.message
        });
      });
    }
  }
}
</script>
<style scoped>
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

</style>